const outerRoutes = [
  {
    path: '',
    name: 'OuterPage',
    component: () => import('./Outer'),
    children: [
        {
            path: '',
            name: 'LandingPage',
            component: () => import('./Landing'),
            meta: {
                title: 'The Art of The Possible | Imagine',
            },
        },
        // {
        //     path: 'solutions',
        //     name: 'ProductsPage',
        //     component: () => import('./Products'),
        //     meta: {
        //         title: 'Solutions | The Art of The Possible | Imagine',
        //     },
        // },
        // {
        //     path: 'solutions/:topicName',
        //     name: 'ProductDetailsPage',
        //     component: () => import('./ProductDetails'),
        //     meta: {
        //         title: '',
        //     },
        // },
        // {
        //     path: 'industry',
        //     name: 'IndustryPage',
        //     component: () => import('./Industry'),
        //     meta: {
        //         title: 'Industry | The Art of The Possible | Imagine',
        //     },
        // },
        // {
        //     path: 'industry/:topicName',
        //     name: 'IndustryDetailsPage',
        //     component: () => import('./IndustryDetails'),
        //     meta: {
        //         title: '',
        //     },
        // },
        // {
        //     path: 'about',
        //     name: 'AboutPage',
        //     component: () => import('./About'),
        //     meta: {
        //         title: 'About | The Art of The Possible | Imagine',
        //     },
        // },
        // {
        //     path: 'faq',
        //     name: 'FAQPage',
        //     component: () => import('./FAQ'),
        //     meta: {
        //         title: 'FAQ | The Art of The Possible | Imagine',
        //     },
        // },
        {
            path: 'privacy-policy',
            name: 'PrivacyPolicyPage',
            component: () => import('./PrivacyPolicy'),
            meta: {
                title: 'Privacy Policy | The Art of The Possible | Imagine',
            },
        },
        {
            path: 'terms-of-use',
            name: 'TermsOfUsePage',
            component: () => import('./TermsOfUse'),
            meta: {
                title: 'Terms Of Use | The Art of The Possible | Imagine',
            },
        },
        // {
        //     path: 'blog',
        //     name: 'BlogPage',
        //     component: () => import('./Blog'),
        //     meta: {
        //         title: 'Blog | The Art of The Possible | Imagine',
        //     },
        // },
        // {
        //     path: 'blog/:topicName',
        //     name: 'BlogDetailsPage',
        //     component: () => import('./BlogDetails'),
        //     meta: {
        //         title: '',
        //     },
        // },
        // {
        //     path: 'press-release/:topicName',
        //     name: 'PressReleaseDetailsPage',
        //     component: () => import('./PressReleaseDetails'),
        //     meta: {
        //         title: '',
        //     },
        // }
    ]
  }
]

export default outerRoutes
