import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const opts = {
	theme: {
		themes: {
			light: {
				accent: '#EDD46F',
				black: '#353839',
				white: '#f5f5f5'
			},
		},
	},
	icons: {
		iconfont: 'mdi',
	},
}

export default new Vuetify(opts)
