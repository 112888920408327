import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        firstTimeLoad: true,
        scrollDirection: null,
        // baseUrl: 'http://localhost:8000'
        // baseUrl: 'http://18.222.147.239'
        baseUrl: 'https://www.imagine.tech'
    },
    getters: {
        getFirstTimeLoadValue(state) {
            return state.firstTimeLoad
        },
        getScrollDirection(state) {
            return state.scrollDirection
        },
        getBaseUrl(state) {
            return state.baseUrl
        },
    },
    mutations: {
        setFirstTimeLoadValue(state) {
            state.firstTimeLoad = false
        },
        setScrollDirection(state, payload) {
            state.scrollDirection = payload
        }
    },
    actions: {
        loadingFinished(context) {
            context.commit('setFirstTimeLoadValue')
        },
        windowBeingScrolled(context, payload) {
            context.commit('setScrollDirection', payload)
        }
    },
    modules: {
    }
})
