import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify'
import Axios from 'axios'
import VueGtm from 'vue-gtm'

Vue.use(VueGtm, {
    id: 'GTM-WNN62R4'
})

Vue.prototype.$http = Axios

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
