import Vue from 'vue'
import VueRouter from 'vue-router'
import outerRoutes from '@/views/Outer/router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        ...outerRoutes,
        {
            path: '/get-started',
            component: () => import ('@/views/BMC'),
            meta: {
                title: 'Getting Started | The Art of The Possible | Imagine',
            }
        },
        {
            path: '/404',
            component: () => import('@/views/NotFoundPage'),
            meta: {
                title: 'Not found | The Art of The Possible | Imagine',
            },
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title ? to.meta.title : document.title
    }
    next();
})

export default router
